<template>
<!-- zx  2021-6-4 -->
<div>
  <!-- <div class="command1" v-lazy:background-image="require('./emergency_command_img/banner.png')"> -->
  <div class="command1">
     <div class="start">
       <img v-lazy='require("./emergency_command_img/rVY57iRW.webp")' class="start_img">
     </div>
     <div class="bannert">
        <div class="type1">
        应急指挥
      </div>
      <div class="type2">精准定位GIS平台报警位置，信息化平台进行整合。最大范围内实现协同指挥调度</div>
     </div>
       <div class="type1_1"></div>
  </div>
  <div class="command2">
    <div class="type3">GIS定位</div>
    <div class="type4">地理信息系统集中、存储、操作、和显示地理位置信息，能够应用于地形调查、发展规划、绘图和路线规划</div>
   <div class="img-1">
      <img
    v-lazy="require('./emergency_command_img/Ty0HuD4H.webp')"
     class="type5">
   </div>
  </div>
  <!-- <div class="command3" v-lazy:background-image="require('./emergency_command_img/img2.png')"> -->
  <div class="command3" >
     <div class="img-2">
       <img v-lazy='require("./emergency_command_img/fG3fVyTo.webp")' class="img-3">
     </div>
   <div class="linteral2">
      <div class="type6">安全出口</div>
    <div class="type7">景区配置多个安全出口，安全通道，及时有效疏散人流</div>
   </div>
  </div>
  <div class="command4">
    <div class="type3">安保人员管理</div>
    <div class="type4">专业安保人员，第一时间响应信息，并进行处理，确保大家的生命财产安全</div>
      <div class="img-1">
        <img v-lazy="require('./emergency_command_img/img6.png')" class="type5">
      </div>
  </div>
  <!-- <div class="command5" v-lazy:background-image="require('./emergency_command_img/img(1).png')"> -->
   <div class="command5">
  <div class="img-2">
       <img v-lazy='require("./emergency_command_img/9HlKFgH0.webp")' class="img-3">
     </div>
   <div class="linteral2">
      <div  class="type6">24小时不间断检查</div>
    <div class="type7">全面、无死角，安排巡查时间，确保无意外发生</div>
   </div>
  </div>
 <div>
  <Header></Header>
  <Footer></Footer>
  </div>
</div>
</template>
<script>
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped>
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

 .command1{
     height: 600px;
    background-repeat:  no-repeat;
    background-size: 100% 100%;
  }  
  .start{
   height: 600px;
  width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 600px;
  width: 100%;
	}
}
  .bannert{
    z-index: 5;
   position: absolute;
    left: 361px;
    top: 230px;

}
  .type1_1{
    position: absolute;
    top: 0;
    left: 0;
    height:600px;
    width:100%;
    background-color: rgba(0,0,0,.4);
  }
  .type1{
    font-size: 48px;
    color: #ffffff;
    // margin-top: -370px;
    // padding-left: 360px;
    text-align: left;
  }
  .type1{
    animation: sport 0.5s;
  transform: translateY(0px);
  }
  .type2{
    font-size: 24px;
    color: #ffffff;
    margin-top: 24px;
    // padding-left: 360px;
    width: 675px;
    text-align: left;
  }
  .type2{
     animation: sport 0.5s;
  transform: translateY(0px);
  }
  .command2{
    padding-top: 80px;
    padding-bottom: 100px;
    margin: auto;
  }
  .type3{
     color: rgba(51, 51, 51, 1);
  font-size: 40px;
  }
  .type4{
    color: rgba(86, 86, 86, 1);
  font-size: 20px;
  margin-top: 24px;
  }
  .type5{
    width: 1200px;
    height: 420px;
    // margin-top: 60px;
  }
  .img-1{
    width: 1200px;
    height: 420px;
    margin: auto;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 150px;
		height: 150px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 1200px;
    height: 420px;
	}
  }
  .command3{
    height: 720px;
    // background-repeat:  no-repeat;
    // background-size: 100% 100%;
    margin-bottom: 100px;
    position: relative;
  }
  .img-3{
     height: 720px;
     width: 100%;

  }
  .linteral2{
    position: absolute;
    top: 310px;
    left: 698px;
  }
  .img-2{
      height: 720px;
     width: 100%;
      display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		   height: 720px;
     width: 100%;
	}
  }
  .type6{
    color: rgba(255, 255, 255, 1);
  font-size: 40px;
  // padding-top: 310px;
  }
  .type7{
    color: rgba(255, 255, 255, 0.88);
  font-size: 20px;
  margin-top: 24px;
  }
  .command4{
    margin-bottom: 100px;
  }
  .command5{
     height: 720px;
    // background-repeat:  no-repeat;
    // background-size: 100% 100%;
    position: relative;
  }
</style>